<template>
  <!--
    A collection of functions and components related to one device.
  -->
  <div>
    <Portlet 
      :title="portletTitle" 
      :sub-title="portletSubtitle" 
      icon="tablet-alt"
      class="deviceDetail"
    >
      <template slot="buttons">
        <div
          v-if="isSalesTerminal && authenticationHasRole('scope_staff') && (authenticationHasRole('admin') || authenticationHasRole('secondlevel'))"
          class="btn-group btn-group-sm mr-2 d-none d-md-block"
        >
          <button
            class="btn btn-primary btn-sm"
            @click="showSidebarDecrypt()"
          >    
            <font-awesome-icon
              class="mr-2"
              icon="lock"
            />Encrypt Reseller Credentials
          </button>
        </div>
        <div
          v-if="!authenticationHasRole('zerolevel') && !authenticationHasRole('firstlevel')"
          class="btn-group btn-group-sm d-none d-md-block"
        >
          <!-- links to jira and gekko (to edit the devices-settings) -->
          <a
            v-if="deviceId != null && deviceId != 'null'"
            :href="linkToGekko"
            class="btn btn-primary btn-sm"
            target="_blank"
          >    
            <font-awesome-icon
              class="mr-2"
              icon="edit"
            />{{ $t('edit') }}</a>
        </div>
        <div
          v-if="!authenticationHasRole('zerolevel')"
          class="d-md-none"
        >
          <button
            v-if="isSalesTerminal && authenticationHasRole('scope_staff') && (authenticationHasRole('admin') || authenticationHasRole('secondlevel'))"
            class="btn btn-primary btn-sm"
            @click="showSidebarDecrypt()"
          >    
            <font-awesome-icon
              class="mr-2"
              icon="lock"
            />Encrypt Reseller Credentials
          </button>
          <!-- links to jira and gekko (to edit the devices-settings) -->
          <a
            v-if="deviceId != null && deviceId != 'null'"
            :href="linkToGekko"
            class="btn btn-primary btn-sm"
            target="_blank"
          >            
            <font-awesome-icon
              class="mr-2"
              icon="edit"
            />{{ $t('edit') }}</a>
          <a
            v-if="installationId != null && installationId != 'null'"
            :href="jiraUrl"
            class="btn btn-primary btn-sm"
            target="_blank"
          >
            <font-awesome-icon
              class="mr-2"
              :icon="['fab', 'jira']"
            />{{ $t('jira') }}</a>
        </div>
      </template>
      
      <LoadingPlaceholder v-if="loading" />
      <template v-else>
        <template v-if="device && device.name">
          <ul class="nav nav-tabs mx-4">
            <li class="nav-item">
              <a
                :class="['nav-link', { 'active' : !conf}]"
                href="#operation"
                data-toggle="tab"
                aria-controls="operation"
                aria-selected="true"
              >{{ $t('operation') }}</a>
            </li>
            <li
              v-if="!authenticationHasRole('zerolevel')"
              class="nav-item"
            >
              <a
                class="nav-link"
                href="#details"
                data-toggle="tab"
                aria-controls="details"
                aria-selected="true"
              >{{ $t('details') }}</a>
            </li>
            <li
              v-if="!authenticationHasRole('zerolevel')"
              class="nav-item"
            >
              <a
                class="nav-link"
                href="#commands"
                data-toggle="tab"
                aria-controls="commands"
                aria-selected="true"
              >{{ $t('commands') }}</a>
            </li>
            <li
              v-if="!authenticationHasRole('zerolevel')"
              class="nav-item"
            >
              <a
                :class="['nav-link', { 'active' : conf}]"
                href="#configuration"
                data-toggle="tab"
                aria-controls="configuration"
                aria-selected="true"
              >{{ $t('configuration') }}</a>
            </li>
            <li
              v-if="!authenticationHasRole('zerolevel')"
              class="nav-item"
            >
              <a
                class="nav-link"
                href="#helperCommands"
                data-toggle="tab"
                aria-controls="helperCommands"
                aria-selected="true"
              >{{ $t('deviceCommandDialogComp.helper') }}</a>
            </li>
            <li 
              v-if="!authenticationHasRole('zerolevel')"
              class="nav-item"
            >
              <a
                class="nav-link"
                href="#deviceLog"
                data-toggle="tab"
                aria-controls="deviceLog"
                aria-selected="true"
              >{{ $t('deviceLog') }}</a>
            </li>
            <li
              v-if="!authenticationHasRole('zerolevel')"
              class="nav-item"
            >
              <a
                class="nav-link"
                href="#audit"
                data-toggle="tab"
                aria-controls="audit"
                aria-selected="true"
              >{{ $t('auditLog') }}</a>
            </li>
            <li
              v-if="!authenticationHasRole('zerolevel')"
              class="nav-item"
            >
              <a
                class="nav-link"
                href="#auditCommandLog"
                data-toggle="tab"
                aria-controls="auditCommandLog"
                aria-selected="true"
              >{{ $t('deviceDetailComponent.auditCommandsLog') }}</a>
            </li>
          </ul>

          <div class="tab-content px-4">
            <div
              id="operation"
              :class="['tab-pane', { 'active' : !conf}]"
              role="tabpanel"
              aria-labelledby="operation"
            >
              <div class="row">
                <div class="col-12 col-lg-6">
                  <Portlet
                    v-if="!authenticationHasRole('zerolevel')"
                    :title="$t('deviceDetailComponent.quickSupport')"
                    class="mb-4"
                    icon="info-circle"
                  >
                    <DeviceDetailCommonTable
                      ref="commonTable"
                      :is-terminal="isSalesTerminal"
                      :device="device"
                      :is-vnc="isVnc"
                      :installation-id="installationId"
                    />
                  </Portlet>

                  <Portlet
                    v-if="hasDeviceDetailCameraImage"
                    :title="$t('deviceDetailComponent.screenshot')"
                    icon="image"
                  >
                    <template slot="buttons">
                      <toggle-button
                        v-model="screenAutoRefresh"
                        :labels="{ checked: 'Autorefresh on', unchecked: 'Autorefresh off' }"
                        :color="{ checked: 'green', unchecked: 'gray' }"
                        :height="30"
                        :width="115"
                        :font-size="10"
                        class="m-0 mr-2"
                        :sync="true"
                      />
                      <a
                        href="#"
                        m-portlet-tool="reload"
                        class="m-portlet__nav-link m-portlet__nav-link--icon reloadButton"
                        @click.prevent="$refs.deviceDetailScreenshot.loadScreenshot(); spinScreenshot(true)"
                      >
                        <font-awesome-icon
                          :class="['alt-pointer color-primary', { 'fa-spin' : loadingScreenshot}]"
                          icon="sync-alt"
                        />
                      </a>
                    </template>
                    <DeviceDetailScreenshot
                      ref="deviceDetailScreenshot"
                      :uuid="device.uuid"
                      :auto-refresh="screenAutoRefresh"
                      @stopReloadingScreenshot="spinScreenshot"
                      @stopAutorefresh="changeAutoRefresh"
                    />
                  </Portlet>
                </div>
                <div class="col-12 col-lg-6">
                  <DeviceZabbixDialogStatus
                    v-if="device.uuid && isTerminal"
                    class="mb-4"
                    :uuid="device.uuid"
                    :is-photoprint="isPhotoprintTerminal"
                    :single-side="singleSide"
                  />
                  <DeviceCommandDialogLiveStatus
                    v-if="!authenticationHasRole('zerolevel')"
                    :device-is-active="activeDevice"
                    :device-id="deviceId"
                  />
                </div>
              </div>
            </div>

            <div
              v-if="!authenticationHasRole('zerolevel')"
              id="details"
              class="tab-pane"
              role="tabpanel"
              aria-labelledby="details"
            >
              <Portlet
                :title="$t('deviceDetailComponent.details')"
                class="mb-4"
                icon="info-circle"
              >
                <DeviceDetailTable
                  :is-terminal="isTerminal"
                  :device="device"
                />
              </Portlet>
            </div>

            <div
              id="commands"
              class="tab-pane"
              role="tabpanel"
              aria-labelledby="commands"
            >
              <DeviceCommandDialog
                v-if="activeDevice"
                :device-is-active="activeDevice"
                :device-id="deviceId"
                :device-commands="device.commands"
              />

              <EmergencyDeviceCommandDialog
                v-if="!activeDevice"
                :device-id="deviceId"
              />
            </div>
            
            <div
              id="configuration"
              :class="['tab-pane', { 'active' : conf}]"
              role="tabpanel"
              aria-labelledby="configuration"
            >
              <div class="row">
                <div class="col-12 col-md-9">
                  <DeviceDetailConfiguration
                    v-if="device.uuid"
                    ref="deviceDetailConfiguration"
                    :application-type="device.applicationType"
                    :configuration-id="device.activeDeviceConfigurationId"
                    :uuid="device.uuid"
                    :device="device"
                  />
                </div>

                <div class="col-12 col-md-3">
                  <Portlet
                    v-if="hasDeviceDetailScreenshot"
                    :title="$t('deviceDetailComponent.camera')"
                    icon="image"
                  >
                    <template slot="buttons">
                      <a
                        href="#"
                        m-portlet-tool="reload"
                        class="m-portlet__nav-link m-portlet__nav-link--icon reloadButton"
                        @click.prevent="$refs.deviceDetailCameraImage.getPhotoDetails()"
                      >
                        <font-awesome-icon
                          icon="sync-alt"
                        />
                      </a>
                    </template>
                    <DeviceDetailCameraImage
                      ref="deviceDetailCameraImage"
                      :uuid="device.uuid"
                    />
                  </Portlet>
                </div>
              </div>
            </div>
            
            <div
              v-if="commandsAvailable(externalLinks)"
              id="helperCommands"
              class="tab-pane"
              role="tabpanel"
              aria-labelledby="helperCommands"
            >
              <DeviceCommandDialogExternalLink
                :device-is-active="activeDevice"
                :external-links="externalLinks"
              />
            </div>

            <div
              v-if="!authenticationHasRole('zerolevel')"
              id="audit"
              class="tab-pane"
              role="tabpanel"
              aria-labelledby="audit"
            >
              <AuditLog :device-id="deviceId" />
            </div>
            
            <div
              v-if="!authenticationHasRole('zerolevel')"
              id="auditCommandLog"
              class="tab-pane"
              role="tabpanel"
              aria-labelledby="auditCommandLog"
            >
              <AuditCommandLog :device-id="deviceId" />
            </div>
            <div
              id="deviceLog"
              class="tab-pane"
              role="tabpanel"
              aria-labelledby="deviceLog"
            >        
              <DeviceCommandDialogTextOutput
                :device-id="deviceId"
                :device-is-active="activeDevice"
                :commands="loggingCommands"
              />
            </div>
          </div>
        </template>
      </template>
      <Sidebar
        v-if="showSidebar"
        :show-sidebar="showSidebar"
        @close="close"
      >
        <form>
          <div class="form-group">
            <h4 class="m-0 mt-3">
              Encrypt Reseller Credentials
            </h4>
            <hr class="m-0 mb-3">
            <label>Reseller {{ $t('username') }}</label>
            <input
              v-model="terminal.username"
              v-focus
              class="form-control"
              type="text"
            >
            <hr class="m-0 mb-3">
            <label> Reseller {{ $t('deviceDetailTableComponent.password') }}</label>
            <input
              v-model="terminal.password"
              class="form-control"
              type="text"
            >
          </div>
          <button
            class="btn btn-primary float-right"
            :disabled="!terminal.username || !terminal.password"
            @click.prevent="saveCredentials()"
          >
            <font-awesome-icon
              class="mr-2"
              icon="check"
            />{{ $t('save') }}
          </button>
          <div class="clearfix" />
        </form>
      </Sidebar>
    </Portlet>
  </div>
</template>

<script>
// import mixins
import { dateTimeMixin } from '@/mixins/dateTimeMixin.js';
import { urlParseMixin } from '@/mixins/urlParseMixin.js';
import { authenticationMixin } from '@/mixins/authenticationMixin';
// import computed
import { mapGetters } from 'vuex';
import Sidebar from '@/components/Base/Sidebar.vue';

export default {
  name: "DeviceDetail",
  components: {
    Sidebar,
    DeviceCommandDialog: () => import('@/components/Device/DeviceCommandDialog.vue'),
    DeviceDetailScreenshot: () => import('@/components/Device/DeviceDetail/DeviceDetailScreenshot.vue'),
    DeviceDetailCameraImage: () => import('@/components/Device/DeviceDetail/DeviceDetailCameraImage.vue'),
    DeviceDetailTable: () => import('@/components/Device/DeviceDetail/DeviceDetailTable.vue'),
    DeviceDetailCommonTable: () => import('@/components/Device/DeviceDetail/DeviceDetailCommonTable.vue'),
    DeviceDetailConfiguration: () => import('@/components/Device/DeviceDetail/DeviceDetailConfiguration.vue'),
    EmergencyDeviceCommandDialog: () => import('@/components/Device/DeviceDetail/EmergencyDeviceCommandDialog.vue'),
    DeviceZabbixDialogStatus: () => import('@/components/Device/DeviceZabbixDialogStatus.vue'),
    DeviceCommandDialogLiveStatus: () => import('@/components/Device/DeviceCommandDialogLiveStatus.vue'),
    AuditLog: () => import('@/components/Device/DeviceDetail/AuditLog.vue'),
    AuditCommandLog: () => import('@/components/Device/DeviceDetail/AuditCommandLog.vue'),
    DeviceCommandDialogTextOutput: () => import('@/components/Device/DeviceCommandDialogTextOutput.vue'),
    DeviceCommandDialogExternalLink: () => import('@/components/Device/DeviceCommandDialogExternalLink.vue')
  },
  mixins: [
    dateTimeMixin,
    urlParseMixin,
    authenticationMixin
  ],
  props: {
    installationId: {
      type: String,
      required: true
    },
    deviceId: {
      type: String,
      required: true
    },
    conf: {
      type: String,
      required: false,
      default: null
    }
  },
  data () {
    return {
      loading: true,
      device: null,
      loadingScreenshot: true,
      screenAutoRefresh: false,
      showSidebar: false,
      terminal: {
        username: null,
        password: null
      }
    }
  },
  computed: {
    portletSubtitle () {
      if (this.device == null) {
        return '';
      }
      if (this.device.created == null) {
        return '';
      }
      if (this.device.updated == null) {
        return '';
      }
      return `${ this.$t('deviceDetailComponent.created') }: ${ this.dateTime_dateTime(this.device.created) }; ${ this.$t('deviceDetailComponent.updated') }: ${ this.dateTime_dateTime(this.device.updated) }`;
    },
    portletTitle () {
      if (this.device == null) {
        return '';
      }
      if (this.device.name == null) {
        return '';
      }
      return this.device.name;
    },
    hasDeviceDetailCameraImage: function () {
      let applicationType = this.device.applicationType.toLowerCase();
      let allowedTypes = [
        "action_camera",
        "qt_kiosk",
        "sc_camera",
        "terminal-v1",
        "terminal-v2",
        "terminal-ADVT",
        "terminal_liveview_photopoint",
        "photopoint_liveview",
        "mediastreamer",
        "terminal-photoprint"
      ];
      let sorted = [];
      for (let i = 0; i < allowedTypes.length; i++) {
        sorted.push(allowedTypes[i].toLowerCase());
      }
      if (sorted.indexOf(applicationType) > -1) {
        return true;
      }
      if(applicationType.toLowerCase().includes("terminal-advt")) {
        return true;
      }
      if(applicationType.toLowerCase().includes("terminal-photoprint")) {
        return true;
      }
      return false;
    },
    hasDeviceDetailScreenshot: function () {
      let applicationType = this.device.applicationType.toLowerCase();
      let allowedTypes = [
        "qt_kiosk",
        "qt_tv",
        "terminal-v1",
        "terminal-v2",
        "terminal-ADVT",
        "terminal_liveview_photopoint",
        "photopoint_liveview",
        "mediastreamer",
        "terminal-photoprint"
      ];
      let sorted = [];
      for (let i = 0; i < allowedTypes.length; i++) {
        sorted.push(allowedTypes[i].toLowerCase());
      }
      if (sorted.indexOf(applicationType) > -1) {
        return true;
      }
      if(applicationType.toLowerCase().includes("terminal-advt")) {
        return true;
      }
      if(applicationType.toLowerCase().includes("terminal-photoprint")) {
        return true;
      }
      return false;
    },
    ...mapGetters([
      'store_getGekkoUrl',
      'store_getJiraUrl'
    ]),
    linkToGekko: function () {
      return this.urlParseMixin_combine(this.store_getGekkoUrl, `devices/${ this.deviceId }`);
    },
    jiraUrl: function () {
      return this.urlParseMixin_combine(this.store_getJiraUrl, `browse/${ this.installationId }`);
    },
    activeDevice: function () {
      if (this.device && this.device.vpnIpAddress) {
        return true;
      }
      return false;
    },
    loggingCommands: function () {
      if (!this.device.commands) {
        return new Array();
      }
      return this.device.commands.filter(p => p.category == "logging");
    },
    externalLinks: function () {
      if (!this.device.commands) {
        return new Array();
      }
      return this.device.commands.filter(p => p.category == "helper");
    },
    isSalesTerminal () {
      return this.device && (this.device.applicationType.includes("terminal-ADVT") || this.device.applicationType.includes("terminal-photoprint"));
    },
    isVnc () {
      let allowedTypes = [
        "qt_kiosk",
        "qt_tv",
        "terminal-v",
        "terminal-ADVT",
        "terminal_liveview_photopoint",
        "photopoint_liveview",
        "mediastreamer",
        "terminal-photoprint"
      ];
      return this.device && allowedTypes.some(x => this.device.applicationType.toLowerCase().startsWith(x.toLowerCase()));
    },
    isTerminal () {
      return this.device && (this.device.applicationType.includes("terminal-ADVT") || this.device.applicationType.includes("terminal-photoprint") 
      || this.device.applicationType.includes("terminal-v") || this.device.applicationType.includes("photopoint_liveview"));
    },
    isPhotoprintTerminal () {
      return this.device && this.device.applicationType.includes("terminal-photoprint");
    },
    singleSide () {
      return this.device && (this.device.applicationType.includes("terminal-v") || this.device.applicationType.includes("photopoint_liveview"));
    }
  },
  async created () { 
    await this.getDevice();
    await this.getDeviceCommands();
  },
  methods: {
    show () {
      this.showSidebar = true;
    },
    close () {
      this.showSidebar = false;
    },
    showSidebarDecrypt () {
      this.show();
    },
    commandsAvailable (commands) {
      if (commands == null) {
        return false;
      }
      if (commands.length == null) {
        return false;
      }
      if (commands.length == 0) {
        return false;
      }
      return true;
    },
    saveCredentials () {
      this.axios.post(`/CentralDeviceManagement/SaveTerminalCredentials?deviceUuid=${this.deviceId}&configurationId=${this.device.activeDeviceConfigurationId}`, this.terminal)
        .then((response) => {
          if (response == null) {
            return;
          }
          if (response.data == null) {
            return;
          }
          // save the response as devices
          this.getDevice()
          .then(() => { this.getDeviceCommands()});
        })
        .finally(() => {
          this.terminal = {
            username: null,
            password: null
          }
          this.close();
        })
    },
    changeAutoRefresh (val) {
      this.screenAutoRefresh = val;
    },
    spinScreenshot (val) {
      this.loadingScreenshot = val;
    },
    pictureSrc (name, image) {
      return 'data:' + name + ';base64,' + image;
    },
    // GET DEVICE (gets the data for the device specified by the deviceId)
    async getDevice () {
      this.loading = true;
      await this.axios.get(`/CentralDeviceManagement/Get?deviceUuid=${this.deviceId}`)
        .then((response) => {
          if (response == null) {
            return;
          }
          if (response.data == null) {
            return;
          }
          // save the response as devices
          this.device = response.data; 
        })
    },
    async getDeviceCommands (){
        await this.axios.get(`/CentralDeviceManagement/GetDeviceCommands?deviceUuid=${this.deviceId}`) 
        .then((commands) => {
            this.device.commands = commands.data;         
        })
        .finally(() => {
          this.loading = false;
        }); 
    },
  }
}
</script>

<style scoped>
.jiraImage {
  height: 1rem;
  width: auto;
}
</style>

